body {
    background-color: #ccc;
}

.th-initiative {
    width: 110px;
}

.th-initiative i.material-icons {
    right: 10px;
}

.th-ac,
.th-hp {
    width: 95px;
}

.th-rm {
    width: 60px;
}

.th-player-btns span {
    width: 82px;
    display: inline-block;
}

.th-hpbtn {
    width: 150px;
}

.td-hp-change button {
    width: 32px;
    padding: 0.0rem .4rem 0.25rem;
    font-size: 16px;
}

.td-hp-change .input-group input {
    width: 52px;
    flex: none;
}

.btn.remove-contender {
    width: 32px;
}

.btn.pl-5 i.material-icons {
    position: absolute;
    left: 8px;
    font-size: 20px;
    top: 7px;
}

.tab-btns {
    padding-left: 0;
}

th .material-icons {
    position: absolute;
    top: 17px;
    font-size: 15px;
    right: 40px;
}

.table-btn {
    padding: 0;
    height: 25px;
    background-color: transparent;
}

#monster-results,
#spell-results{
    z-index: 1080;
    top: 75px;
    height: 343px;
}

.white-space-pre {
    white-space: pre-wrap;
}

.rc-color-picker-wrap {
    height: 20px;
    width: 20px;
}

.rc-color-picker-trigger {
    border-radius: 15px;
}

.generated-event span:nth-child(2) {
    color: #009DF8;
}

.generated-event span:nth-child(1) {
    color: #F1FAFF;
}

.generated-event span:nth-child(3) {
    color: #D3FBD8;
}

.overlay {
    background-color: rgba(100, 100, 100, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
}

.overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.npc-item i {
    font-size: 19px;
}

#npc-content {
    width: 100%;
    height: 800px;
}

.dice-option span {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #ccc;
    text-align: center;
    width: 25px;
    height: 25px;
}

.dice-row:hover, .dice-option.hovering {
    background-color: #555;
}

.dice-option.selected, .dice-option:hover {
    background-color: #ccc;
    color: #333;
}

.form-group.form-ft::before {
    content: 'ft';
    position: absolute;
    right: 39px;
    bottom: 7px;
    color: #999;
}

.btn i.material-icons svg {
    position: absolute;
}

.card-header i.md-light.edit {
    font-size: 21px;
}

.spell-name .material-icons {
    font-size: 14px;
}